<template>
  <div class="account" style="padding-bottom: 100px">
    <top-fixed></top-fixed>

    <div style="font-size: 20px; text-align: center; margin-top: 0.6rem">
      {{ $t("hj27") }}
    </div>
    <div style="margin: 0.7rem 0.6rem">
      <div
        @click="changeLnag('en')"
        style="
          display: flex;
          justify-content: space-between;
          height: 1.1rem;
          align-items: center;
          padding: 0.6rem;
          background: #f4f4f4;
          border-radius: 10px;
          margin-bottom: 0.6rem;
        "
      >
        <span style="color: #000000; font-size: 18px">English</span>
        <img
          style="width: 0.8rem; height: 0.8rem"
          v-if="nowLang !== 'en'"
          src="../assets/img2/lang2.png"
          alt=""
        />
        <img
          style="width: 0.8rem; height: 0.8rem"
          v-else
          src="../assets/img2/lang.png"
          alt=""
        />
      </div>
      <div
        @click="changeLnag('zh_TW')"
        style="
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid #fefefe;
          height: 1.1rem;
          align-items: center;
          padding: 0.6rem;
          background: #f4f4f4;
          border-radius: 10px;
        "
      >
        <span style="color: #000000; font-size: 18px">繁體中文</span>
        <img
          style="width: 0.8rem; height: 0.8rem"
          v-if="nowLang != 'zh_TW'"
          src="../assets/img2/lang2.png"
          alt=""
        />
        <img
          style="width: 0.8rem; height: 0.8rem"
          v-else
          src="../assets/img2/lang.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>
  
  
  <script>
import TopFixed from "../components/TopFixed.vue";


export default {
  name: "AccountView",
  data () {
    return {
      index: 0,
      nowLang: '',
    };
  },
  created () {
    this.nowLang = window.localStorage.getItem("language") ? window.localStorage.getItem("language") : 'zh_TW'
  },
  components: { TopFixed },
  methods: {
    changeLnag (value) {
      window.localStorage.setItem("language", value)
      this.$i18n.locale = value;
      this.nowLang = value
    },
  },
};
</script>




  